import { TfiArrowCircleLeft, TfiImport } from "react-icons/tfi";
import { TfiArrowCircleRight } from "react-icons/tfi";
import { IoIosArrowBack, IoIosArrowForward, IoMdTime } from "react-icons/io";
import { BsPencil } from "react-icons/bs";
import { MdKeyboardArrowDown, MdOutlineAllInbox, MdOutlineClose } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { HiOutlineArrowSmLeft, HiPlus } from "react-icons/hi";
import { useEffect, useRef, useState } from "react";
import appDefaults from "../../../../helpers/app-defaults";
import Sections from "./Sections";
import { ErrorMessage, Field, Formik, Form as FormF, FastField } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import DatePicker from "react-date-picker";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import Switch from "react-switch";
import * as Yup from "yup";
import { errorAlert } from "../../../../services/toast.service";
import teamService from "../../../../services/team.service";
import { formatAMPM, renderCustomerName, timeZoneHeader } from "../../../../helpers/helperFunctions";
import momentTz from "moment-timezone"
import Swal from "sweetalert2";
import { CHANGE_TASK_STATUS_API, CREATE_TASK_API, MAIN_SEARCH_API, UPDATE_MISSION_API } from "../../../../redux/task/actionTypes";
import moment from "moment-timezone";
import Calendar from "react-calendar";
import closeicon from "../../../../assets/image/close-icon.png"
import arrow_in_circle from "../../../../assets/image/arrow_in_circle.png"
import optimize from "../../../../helpers/OptimizeMission";
import ScheduleDelivery from "./ScheduleDelivery";
import profilecirclicon from "../../../../assets/image/profilecirclicon.png";
import TaskRightSideDetails from "./RightSideTaskDetails";
import authService from "../../../../services/auth.service";
import uploadplusicon from "../../../../assets/image/plusuploadicon.png";
import documenticon from "../../../../assets/image/document.png";
import CreatableSelect from 'react-select/creatable';
const timezone = { ...timeZoneHeader() }

const RightSideBar = (props) => {
    const { teamList } = useSelector((state) => state.team)
    const userData = useSelector((state) => state.auth.user)
    const { countryCode } = useSelector((state) => state.authSaga)
    const superAdminRole = userData && userData.role && userData.role === 1 ? true : false;
    const history = useHistory();
    const [moreInfo, setMoreInfo] = useState(false)
    const [fromSearch, setFromSearch] = useState(false)
    const [collapseState, setCollapseState] = useState({
        assignTo: "",
        startDate: moment(new Date()).toDate(),
        dueDate: moment(new Date()).toDate(),
        priority: "none",
        watcher: "",
        estimate: "",
        workedOn: moment(new Date()).toDate(),
        progress: "",
        riskStatus: "",
    });

    const uploadInput = useRef(null);
    const [showCalendar, setShowCalendar] = useState(false)
    const dispatch = useDispatch();
    const userMerchantListRef = useRef([])
    const { hubList } = useSelector((state) => state.hub)
    let { agentList, userMerchantList } = useSelector((state) => state.user);
    const { taskPageCount,
        taskPageLimit, tasksList, unAssignedTasksList, tasksListWithouDate, missionsList, datedTaskList, tasksListPendingAction } = useSelector((state) => state.task);

    const validateCustom = (values) => {
        let error = {};
        if (!props.address) {
            error.address = 'Address is required';
        } if (!props.location || !props.location?.lat || !props.location?.lng) {
            error.address = 'Please enter valid address';
        }
        else {
            delete error.address
            delete error.location
        }
        return error;
    };

    useEffect(() => {
        getUserTeams()
    }, [])

    const getUserTeams = async (payload) => {
        let params = { limit: 100 };
        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            params.userIds = userData.permittedUsers;
        }
        if (userData.teamIds) {
            params.teamIds = userData.teamIds;
        }
        if (userData.subAdmin) {
            delete params.userIds;
            delete params.teamIds;
        }
        if (payload && payload.userId) {
            params.userId = payload.userId;
        }
        const response = await teamService.getTeamList(params);
        if (Number(response.status) === 200) {
            props.setUserTeams(response.data.results || []);
        }
    }

    useEffect(() => {
        props.setFormTaskData((prev) => {
            return {
                ...prev,
                teamIds: teamList.map(i => i.id),
                values: teamList
            }
        })
    }, [teamList, props.createTask])

    const cancelOptimiseMission = () => {
        delete props.initialValuesOptimize.maxMissionCapacity
        delete props.initialValuesOptimize.maxMissionDuration
        delete props.initialValuesOptimize.maxMissionTasks
        props.setOptimiseFormData(props.initialValuesOptimize)
        props.setShowOptimiseSection(false)
    };

    const validateCustomTaskStatus = (values, props) => {
        let error = {};
        if (!values.taskStatus || values.taskStatus === "0") {
            error.taskStatus = 'Select task status';
        }
        else {
            delete error.taskStatus
        }
        return error;
    };

    const validationSchemaChangeStatus = Yup.object().shape({

        taskStatus: Yup.string()
            .required("Select task status!")
        // password: Yup.string().required("Password is required!"),
    });

    const clearInitialValues = () => {
        let { taskSettingData, initialValuesTask, regionalSettingData } = props;
        let initialValues = { ...initialValuesTask }
        delete initialValues.merchantId
        delete initialValues.userId
        delete initialValues.createdFor
        delete initialValues.orderDate
        initialValues = {
            ...initialValues,
            after: taskSettingData && (taskSettingData.defaultAfterTime || taskSettingData.defaultAfterTime === "") ? taskSettingData.defaultAfterTime : "",
            afterTimePicker: taskSettingData && (taskSettingData.defaultAfterTimePicker || taskSettingData.defaultAfterTimePicker === "") ? taskSettingData.defaultAfterTimePicker : null,
            before: taskSettingData && (taskSettingData.defaultBeforeTime || taskSettingData.defaultBeforeTime === "") ? taskSettingData.defaultBeforeTime : "",
            beforeTimePicker: taskSettingData && (taskSettingData.defaultBeforeTimePicker || taskSettingData.defaultBeforeTimePicker === "") ? taskSettingData.defaultBeforeTimePicker : null,
            scheduledTimePicker: taskSettingData && (taskSettingData.defaultScheduleTimePicker || taskSettingData.defaultScheduleTimePicker === "") ? taskSettingData.defaultScheduleTimePicker : null,
            scheduledTime: taskSettingData && (taskSettingData.defaultScheduleTime || taskSettingData.defaultScheduleTime === "") ? taskSettingData.defaultScheduleTime : "",
            afterSeconds: taskSettingData && (taskSettingData.defaultAfterTimeSeconds || taskSettingData.defaultAfterTimeSeconds === 0) ? taskSettingData.defaultAfterTimeSeconds : 0,
            beforeSeconds: taskSettingData && (taskSettingData.defaultBeforeTimeSeconds || taskSettingData.defaultBeforeTimeSeconds === 0) ? taskSettingData.defaultBeforeTimeSeconds : 0,
            scheduledTimeSeconds: taskSettingData && (taskSettingData.defaultScheduleTimeSeconds || taskSettingData.defaultScheduleTimeSeconds === 0) ? taskSettingData.defaultScheduleTimeSeconds : 0,
            durationTime: taskSettingData && (taskSettingData.defaultTaskDuration || taskSettingData.defaultTaskDuration === 0) ? taskSettingData.defaultTaskDuration : props.defaultDurationTime,
            capacity: taskSettingData && (taskSettingData.defaultTaskCapacity || taskSettingData.defaultTaskCapacity === 0) ? taskSettingData.defaultTaskCapacity : props.defaultMissionCapacity,
            documents: taskSettingData && (taskSettingData.documents || taskSettingData.documents.length === 0) ? taskSettingData.documents : [],
            capacityUnit: regionalSettingData && (regionalSettingData.volumeUnit) ? regionalSettingData.volumeUnit : "m3",
        }
        return initialValues
    }

    const handleSubmitChangeStatus = async (formValue, { resetForm }) => {
        dispatch({
            type: CHANGE_TASK_STATUS_API,
            data: {
                payload: {
                    taskId: formValue.id,
                    taskStatus: formValue.taskStatus
                },
                resCall: (response) => {
                    if (response.success) {
                        props.setButtonText("Create Task")
                        props.setChangeStatus(false)
                        let initialValue = clearInitialValues()
                        props.setFormTaskData((prev) => {
                            return {
                                ...prev,
                                ...initialValue
                            }
                        });
                        props.setAddress("")
                        resetForm();
                    } else {
                        errorAlert(
                            response.data.message
                                ? response.data.message
                                : "Something went Wrong  !"
                        );
                    }
                }
            }
        })
    }


    const optimiseValidationSchema = Yup.object().shape({
        startFrom: Yup.string()
            .required("Start Location is required!"),
        endAt: Yup.string()
            .required("End Location is required!"),
        // password: Yup.string().required("Password is required!"),
        maxMissionTasks: Yup.number().max(98, "Max mission tasks must be less than or equal to 98")
    });
    useEffect(() => {

        if (userData.role === 2 && userData.userId) {
            let usersList = [];
            if (userMerchantList && userMerchantList.length) {
                usersList = JSON.parse(JSON.stringify(userMerchantList));
                // props.setFormData({ ...props.formData, userId: response.data.results[0].id, merchantId: response.data.results[0].id })
            }
            else {
                usersList = [userData];
            }
            userMerchantListRef.current = usersList
        }
        else {
            if (userMerchantList) {
                let usersList = JSON.parse(JSON.stringify(userMerchantList))
                if (usersList.length) {
                    let found = usersList.find(r => r.id === userData.id)
                    if (!found) {
                        usersList.push(userData)
                    }
                }
                else {
                    usersList = [userData];
                }
                userMerchantListRef.current = usersList
            }
        }
        let user = userData;
        if (user.role === 2 && user.userId) {
            props.setFormTaskData((prev) => {
                return {
                    ...prev,
                    merchantId: user.id,
                    createdFor: userMerchantListRef?.current && userMerchantListRef.current[0] ? userMerchantListRef.current[0].id : user.id,
                    userId: userMerchantListRef?.current && userMerchantListRef.current[0] ? userMerchantListRef.current[0].id : user.id
                }
            })
        }
        else {
            props.setFormTaskData((prev) => {
                return {
                    ...prev,
                    merchantId: user.id,
                    createdFor: user.id,
                    userId: (user.role === 2 || user.role === 4) && user.userId ? user.userId : user.id
                }
            })
        }
    }, [userMerchantList]);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            // .required("Email is required!")
            .email("Please fill valid address"),
        // password: Yup.string().required("Password is required!"),
    });

    const finalTheme = createTheme({
        components: {
            MuiClockPicker: {
                styleOverrides: {
                    root: {
                        "backgroundColor": "#272B30"
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        "border": "none"
                    },
                    input: {
                        padding: "2px 0 2px"
                    }
                }
            }
        }
    })
    let selectAllOption = { id: "all", teamName: "All Teams", firstName: "All Users", lastName: "" }
    const CustomSelectField = ({ field, colors, form, ...propss }) => {
        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                defaultValue={form.values.values && form.values.values.length ? form.values.values : selectAllOption}
                // value={form.values.values && form.values.values.length ? form.values.values : selectAllOption}
                placeholder="Select Team List"
                isMulti
                isClearable={false}
                autoFocus={false}
                isSearchable={false}
                options={getOptions()}
                getOptionLabel={(option) => `${option.teamName}`}
                getOptionValue={(option) => option.id}
                onChange={(newValue, actionMeta) => { onChangeValue(newValue, actionMeta, form) }}
            // components={{ ValueContainer }}
            />
        )
    };


    const onChangeValue = (newValue, actionMeta, form) => {
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option" && option.id === selectAllOption.id) {
            form.setFieldValue("values", teamList);
            form.setFieldValue("teamIds", teamList.map(i => i.id));
        }
        else if (action === "clear") {
            form.setFieldValue("values", []);
            form.setFieldValue("teamIds", []);
        }
        else {
            let findAllSelectedValue = newValue.findIndex(i => i.id === selectAllOption.id)
            if (findAllSelectedValue > -1) {
                newValue.splice(findAllSelectedValue, 1)
            }
            if (newValue.length) {
                form.setFieldValue("values", newValue);
                form.setFieldValue("teamIds", newValue.map(i => i.id));
            }
            else {
                form.setFieldValue("values", []);
                form.setFieldValue("teamIds", teamList.map(i => i.id));
            }
        }
    }
    const getOptions = () => [selectAllOption, ...teamList];
    const handleSubmit = async (formValue, { resetForm }) => {
        try {
            if ((props.features.includedTaskNumber > 0 &&
                ((props.features.additionalTask && (Number(props.features.additionalTaskNumber) + Number(props.features.includedTaskNumber)) > props.taskCount) || props.features.includedTaskNumber > props.taskCount)) ||
                (props.features && props.features.unlimitedTasks) || (superAdminRole)) {
                await createTaskFunc(formValue, resetForm)
            }
            else {
                if (props.buttonText === "Create Task") {
                    // Swal.fire({
                    //     title: "Exceeded qouta",
                    //     text: `You have exceeded default qouta, tasks created now will be charged $${props.features.perTaskCharge || 0.15} per task at the end of month. `,
                    //     icon: "info",
                    //     showCancelButton: true,
                    //     showDenyButton: true,
                    //     color: "#ffffff",
                    //     background: '#151719',
                    //     confirmButtonColor: "#1A1D1F",
                    //     cancelButtonColor: "#1A1D1F",
                    //     denyButtonColor: "#1A1D1F",
                    //     denyButtonText: "Check Business Builder",
                    //     confirmButtonText: "Create Task"
                    // }).then(async (result) => {
                    //     if (result.isDenied) {
                    //         history.push({
                    //             pathname: "/Business/unlimitedTasks"
                    //         })
                    //     }
                    //     if (result.isConfirmed) {
                    await createTaskFunc(formValue, resetForm)
                    //     }
                    // });
                }
                else {
                    await createTaskFunc(formValue, resetForm)
                }
            }

        } catch (err) {
            console.log("error:::", err);
        }
    };
    const escapeRegex = (str) => {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
    };

    let scheduleDeliveryProps = {
        scheduleDeliverySettingData: props.scheduleDeliverySettingData,
        setShowScheduleDelivery: props.setShowScheduleDelivery,
        selectedTasks: props.selectedTasks,
        date: props.date,
        errorAlert,
        taskCounts: props.taskCounts,
        selectedDateRange: props.selectedDateRange
    }

    let createTaskFunc = async (formValue, resetForm) => {
        delete formValue.values
        let usersTeam = [] /*userTeams.map(i => i.id)*/
        if (formValue.createdFor && formValue.createdFor.id !== userData.id)
            formValue.teamIds = [...formValue.teamIds, ...usersTeam]
        if (formValue.phoneNumber) {
            let phone = escapeRegex((formValue.phoneNumber.toString()));
            formValue.phoneNumber = phone;
        }
        let payload = {
            ...formValue,
            orderNo: value,
            docs: props.selectedDocs,
            address: props.address,
            location: props.location,
        }
        if (props.userTeams && props.userTeams.length) {
            payload.userTeamIds = props.userTeams.map(i => i.id)
        }
        dispatch({
            type: CREATE_TASK_API,
            data: {
                payload,
                sortBy: props.taskSortBy,
                // currentPage: currentPage,
                pageLimit: taskPageLimit,
                pageCount: taskPageCount,
                currentDate: props.currentDate,
                resCall: (res) => {
                    handleApiResponse(res, resetForm)
                }
            }
        })
    }

    const handleApiResponse = (response, resetForm) => {
        if (response.success) {
            props.setButtonText("Create task")
            let initialValue = clearInitialValues()
            props.setCreateTask(false);
            props.setEditTask(false);
            props.setFormTaskData((prev) => {
                return {
                    ...prev,
                    ...initialValue
                }
            })
            props.setAddress("")
            setInputValue("")
            // resetForm();
            //docs 
            // props.setSelectedDocs([])
            // if (userData.role === 1) getTeamList()
            // setValues([])
        } else {
            errorAlert(
                response.data.message
                    ? response.data.message
                    : "Something went Wrong  !"
            );
        }
    }


    const deleteDocument = (index, setFieldValue, libraryFiles) => {
        let newLibraryFiles = [...libraryFiles];
        newLibraryFiles.splice(index, 1);
        props.setFormTaskData((prev) => {
            return {
                ...prev,
                libraryFiles: newLibraryFiles,
            };
        });
        setFieldValue("libraryFiles", newLibraryFiles)
    };

    const changeProfileClick = (e) => {
        uploadInput.current.click();
    };
    const handleImageUpload = async (image) => {
        let formDataFile = new FormData();
        formDataFile.append("image", image);
        let response = await authService.uploadFile(formDataFile);
        if (response && response.data) {
            let libraryFiles = props.formTaskData.libraryFiles;
            libraryFiles.push(response.data);
            props.setFormTaskData((prev) => {
                return {
                    ...prev,
                    libraryFiles: libraryFiles,
                };
            });
        }
    };
    const uploadLibraryDocument = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                handleImageUpload(event.target.files[0]);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const components = {
        DropdownIndicator: null,
    };


    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState('');


    const createOption = (label) => ({
        label,
        value: label,
    });

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev) => [...prev, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    const createTaskComponent = () => {
        return (<div className="bottom task-bar-bootom">
            <Formik
                initialValues={props.formTaskData}
                validationSchema={validationSchema}
                validate={validateCustom}
                onSubmit={handleSubmit}
                enableReinitialize={false}
            >{({
                setFieldValue,
                setFieldTouched,
                values,
                resetForm,
                errors,
                touched,
            }) => (
                <FormF>
                    <div className="input-container">
                        <label>Task type</label>
                        <Field
                            // className="form-control"
                            name="orderType"
                            as="select"
                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                            defaultValue={values["orderType"]}
                            onChange={(e) => {
                                let defaultTime = props.defaultDurationTime;
                                switch (e.target.value) {
                                    case "D":
                                        defaultTime = props.taskSettingData && (props.taskSettingData.defaultDrop || props.taskSettingData.defaultDrop === 0) ? props.taskSettingData.defaultDrop : props.defaultDurationTime;
                                        break;
                                    case "P":
                                        defaultTime = props.taskSettingData && (props.taskSettingData.defaultPickup || props.taskSettingData.defaultPickup === 0) ? props.taskSettingData.defaultPickup : props.defaultDurationTime;
                                        break;
                                    case "DI":
                                        defaultTime = props.taskSettingData && (props.taskSettingData.defaultDropService || props.taskSettingData.defaultDropService === 0) ? props.taskSettingData.defaultDropService : props.defaultDurationTime;
                                        break;
                                    case "E":
                                        defaultTime = props.taskSettingData && (props.taskSettingData.defaultExchange || props.taskSettingData.defaultExchange === 0) ? props.taskSettingData.defaultExchange : props.defaultDurationTime;
                                        break;
                                    case "R":
                                        defaultTime = props.taskSettingData && (props.taskSettingData.defaultReturn || props.taskSettingData.defaultReturn === 0) ? props.taskSettingData.defaultReturn : props.defaultDurationTime;
                                        break;
                                    case "S":
                                        defaultTime = props.taskSettingData && (props.taskSettingData.defaultService || props.taskSettingData.defaultService === 0) ? props.taskSettingData.defaultService : props.defaultDurationTime;
                                        break;
                                    default:
                                        break;
                                }
                                setFieldValue("durationTime", defaultTime)
                                // handleOrderTypeChange(e); 
                                setFieldValue("orderType", e.target.value)
                            }}
                        >
                            <option value="P">Pick up</option>
                            <option value="D">Drop</option>
                            <option value="DI">Drop & Service</option>
                            <option value="R">Return</option>
                            <option value="E">Exchange</option>
                            <option value="S">Service</option>
                        </Field>
                    </div>
                    <div className="input-container">
                        <label> Location</label>
                        <Form.Group>
                            {/* <MyAutoCompleteComponent {...autoCompleteProps} /> */}
                            {/* <FaHome /> */}
                            <Autocomplete
                                // apiKey={"AIzaSyC1hE3bjO1vKKaMLso86obGOhtf-gtYtsA"}
                                // libraries={["places"]}
                                className="input-area"
                                value={props.address}
                                onChange={(e) => { props.setAddress(e.target.value); props.setLocation(null) }}
                                placeholder="Address"
                                options={{ types: ["establishment", "geocode"], ...(countryCode && { componentRestrictions: { country: countryCode } }) }}
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                onPlaceSelected={(place) => {
                                    props.setAddress(place.formatted_address);
                                    props.setLocation({
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng(),
                                    });
                                }}
                            />
                            {errors.address ? (
                                <div className="error">{errors.address}</div>
                            ) : null}
                            {!props.address && (
                                <ErrorMessage
                                    name="address"
                                    component="div"
                                />
                            )}
                            {(!props.location || !props.location?.lat || !props.location?.lng) && (
                                <ErrorMessage
                                    name="address"
                                    component="div"
                                />
                            )}
                        </Form.Group>
                    </div>
                    <div className="input-container">
                        <label> Notes</label>
                        <Form.Group className="mb-3">
                            <div className="input-area">
                                <Field
                                    placeholder="Add note for driver..."
                                    name="notes"
                                    aria-label="Notes,product,apt..."
                                    as="textarea"
                                />
                            </div>
                            <ErrorMessage name="notes" component="div" />
                        </Form.Group>
                    </div>
                    <div className="two-inputs">
                        <Form.Group className="input-container">
                            <label>Recipient name</label>
                            <Field
                                className="input-area"
                                placeholder="John Doe"
                                name="firstName"
                                maxLength="35"
                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                aria-label="First Name"
                            />
                            <ErrorMessage
                                name="firstName"
                                component="div"
                            />
                        </Form.Group>
                        {/* <div className="input-container">
                            <label>Phone number</label>
                            <div className="input-area">
                                <input type="text" placeholder="+1 232 323 4343" />
                            </div>
                        </div> */}
                        <Form.Group className="input-container">
                            <label>Phone Number</label>
                            <div className="input-container-phone">
                                <Field className="country-code" aria-label="Phone" name="countryCode" as="select"
                                    defaultValue={"+1"}
                                    onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}>
                                    <option value="+1" >+1</option>
                                    <option value="+44" >+44</option>
                                    <option value="+91">+91</option>
                                </Field>
                                <Field
                                    className="input-area input-area-phone"
                                    placeholder="Phone"
                                    name="phoneNumber"
                                    onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                    aria-label="Phone"
                                />
                            </div>
                            <ErrorMessage
                                name="phoneNumber"
                                component="div"
                            />
                            <ErrorMessage name="countryCode" component="div" />
                        </Form.Group>
                    </div>
                    <div className="input-container order-no">
                        <label>Order No</label>
                        <Form.Group>
                            <CreatableSelect
                                className="react-select-container"
                                classNamePrefix="react-select"
                                components={components}
                                inputValue={inputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={(newValue) => {
                                    setFieldValue("orderNo", newValue)
                                    setValue(newValue)
                                }}
                                onInputChange={(newValue) => setInputValue(newValue)}
                                onKeyDown={handleKeyDown}
                                placeholder="Type something and press enter..."
                                value={value}
                            />
                        </Form.Group>
                    </div>
                    <div className="optional-info" >
                        <div className="top" onClick={() => setMoreInfo(!moreInfo)}>
                            <div className="left">
                                <BsPencil className="icon" />
                                <p style={{ marginBottom: 0 }}>More information (optional)</p>
                            </div>
                            <div className="right">
                                <MdKeyboardArrowDown className="icon  toggle-icon" />
                            </div>
                        </div>

                        {moreInfo && (<>
                            <div className="two-inputs">
                                <div className="input-container">
                                    <label>Email address</label>
                                    <Form.Group>
                                        {/* <Form.Control type="email" placeholder="Email" /> */}
                                        <Field
                                            className="input-area"
                                            placeholder="Email"
                                            name="email"
                                            aria-label="Email"
                                        />
                                        <ErrorMessage name="email" component="div" />
                                    </Form.Group>
                                </div>
                                <div className="input-container">
                                    <label>Date</label>
                                    {/* <div className="input-area"> */}
                                    <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        name={"orderDate"}
                                        value={values["orderDate"] ? new Date(values["orderDate"]) : ""}
                                        onChange={(e) => {
                                            setFieldValue("orderDate", e);
                                            let todayDate = momentTz(props.currentDate).tz(timezone.timezone).format("YYYY-MM-DD")
                                            let selectedDate = momentTz(e).tz(timezone.timezone).format("YYYY-MM-DD");
                                            if (todayDate !== selectedDate) {
                                                setFieldValue("dateChanged", true)
                                            }
                                            else {
                                                setFieldValue("dateChanged", false)
                                            }
                                        }}
                                        selectsEnd
                                    />
                                </div>
                                {/* </div> */}
                            </div>

                            <div className="two-inputs">
                                <div className="input-container">
                                    <label>Arrive between</label>
                                    <Form.Group>
                                        <div className="input-area">
                                            <ThemeProvider theme={finalTheme}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <TimePicker
                                                        inputProps={{ placeholder: "After" }}
                                                        // inputFormat={"hh:mm"}
                                                        open={values.afterOpen}
                                                        ampm={props.regionalSettingData?.timeFormat === "24" ? false : true}
                                                        // onOpen={() => setFieldValue("afterOpen", true)}
                                                        onClose={() => setFieldValue("afterOpen", false)}
                                                        value={values.afterTimePicker}
                                                        onChange={(e) => {
                                                            setFieldValue("afterTimePicker", e)
                                                            let date = new Date(e)
                                                            if (!isNaN(date)) {
                                                                var options = { hour12: false }
                                                                let timeSplit = date.toLocaleTimeString("en", options).split(":")
                                                                setFieldValue("after", `${timeSplit[0]}:${timeSplit[1]}`)
                                                                // setFieldValue("afterTime", `${timeSplit[0]}:${timeSplit[1]} ${timeSplit[0] > 12 ? "pm" : "am"}`)
                                                                setFieldValue("afterSeconds", Number((date.getHours() * 3600) + (date.getMinutes() * 60)))
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField {...params}
                                                            onClick={(e) => setFieldValue("afterOpen", true)}
                                                        />
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </ThemeProvider>
                                        </div>
                                        <ErrorMessage name="after" component="div" />
                                    </Form.Group>
                                </div>
                                <div className="input-container">
                                    <label>And</label>
                                    {/* <div className="input-area">
                                        <input type="text" />
                                    </div> */}
                                    <Form.Group>
                                        <div className="input-area">
                                            <ThemeProvider theme={finalTheme}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <TimePicker
                                                        inputProps={{ placeholder: "Before" }}
                                                        // inputFormat={"hh:mm a"}
                                                        open={values.beforeOpen}
                                                        ampm={props.regionalSettingData?.timeFormat === "24" ? false : true}
                                                        // onOpen={() => setFieldValue("beforeOpen", true)}
                                                        onClose={() => setFieldValue("beforeOpen", false)}
                                                        value={values.beforeTimePicker}
                                                        onChange={(e) => {
                                                            setFieldValue("beforeTimePicker", e)
                                                            let date = new Date(e)
                                                            if (!isNaN(date)) {
                                                                var options = { hour12: false }
                                                                let timeSplit = date.toLocaleTimeString("en", options).split(":")
                                                                setFieldValue("before", `${timeSplit[0]}:${timeSplit[1]}`)
                                                                setFieldValue("beforeSeconds", Number((date.getHours() * 3600) + (date.getMinutes() * 60)))
                                                                // setFieldValue("beforeOpen", false)
                                                                // setFieldValue("beforeTime", `${timeSplit[0]}:${timeSplit[1]} ${timeSplit[0] > 12 ? "pm" : "am"}`)
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField {...params} onClick={(e) => setFieldValue("beforeOpen", true)} />
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </ThemeProvider>
                                        </div>
                                        <ErrorMessage name="before" component="div" />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="two-inputs">
                                <div className="input-container">
                                    <label>Time at stop</label>
                                    <Form.Group className="input-container-cost">
                                        {/* <Form.Control type="text" placeholder="Duration time" /> */}
                                        <Field
                                            type="number"
                                            min="0"
                                            step="any"
                                            className="input-area input-area-text-right"
                                            placeholder="Duration time"
                                            name="durationTime"
                                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                            aria-label="Duration time"
                                        />
                                        <ErrorMessage
                                            name="durationTime"
                                            component="div"
                                        />
                                        <span className="span-text">min</span>
                                    </Form.Group>
                                </div>
                                <div className="input-container">
                                    <label>Capacity</label>
                                    <Form.Group className="input-container-cost" onClick={(e) => (!superAdminRole && props.features && !props.features.vehicleCapacityManagement) ? history.push({
                                        pathname: "/Business/vehicleCapacityManagement"
                                    }) : null}>
                                        {/* <Form.Control type="text" placeholder="Duration time" /> */}
                                        <Field
                                            type="number"
                                            min="0"
                                            step="any"
                                            className="input-area  input-area-text-right"
                                            placeholder="Capacity"
                                            name="capacity"
                                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                            aria-label="Capacity"
                                            disabled={(props.features && props.features.vehicleCapacityManagement) || (superAdminRole) ? false : true}
                                        />
                                        <ErrorMessage
                                            name="capacity"
                                            component="div"
                                        />
                                        <span className="span-text">{values.capacityUnit}</span>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="two-inputs">
                                <div className="input-container">
                                    <label>Assigned organization</label>
                                    <Form.Group>
                                        <Field
                                            className="input-area"
                                            name="createdFor"
                                            as="select"
                                            defaultValue={values["createdFor"]}
                                            onChange={(e) => {
                                                setFieldValue("createdFor", e.target.value)
                                                let findUser = userMerchantListRef.current.find(i => i.id === e.target.value);
                                                if (findUser) {
                                                    if (findUser.role === 2) {
                                                        setFieldValue("userId", e.target.value)
                                                        if (userData.role === 1) {
                                                            setFieldValue("merchantId", e.target.value)
                                                        }
                                                        getUserTeams({ userId: e.target.value }, setFieldValue, "USER")
                                                    }
                                                    else if (findUser.role === 4) {
                                                        // setUserTeams([])
                                                        setFieldValue("merchantId", e.target.value)
                                                    }
                                                }
                                            }}
                                        >
                                            <option value="">
                                                Select User
                                            </option>
                                            {userMerchantListRef.current &&
                                                userMerchantListRef.current.length > 0 &&
                                                userMerchantListRef.current.map((t) => {
                                                    let option = (
                                                        <option value={t.id}>
                                                            {t.firstName ? t.firstName : ""}{" "}
                                                            {t.lastName ? t.lastName : ""}
                                                        </option>
                                                    )
                                                    if (t.role === 2) {
                                                        option = (
                                                            <option value={t.id}>
                                                                {t.companyName ? t.companyName : ""}
                                                            </option>
                                                        )
                                                    }
                                                    return option
                                                })}
                                        </Field>
                                        <ErrorMessage
                                            style={{
                                                fontSize: "10px",
                                                color: "#ea4335",
                                                marginTop: "3px",
                                                marginLeft: "10px",
                                            }}
                                            name="merchantId"
                                            component="div"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="input-container">
                                    <label>Delivery cost</label>
                                    <Form.Group className="input-container-cost">
                                        {/* <Form.Control type="text" placeholder="Duration time" /> */}
                                        <Field
                                            type="number"
                                            min="0"
                                            step="any"
                                            className="input-area  input-area-text-right"
                                            placeholder="Price"
                                            name="price"
                                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                            aria-label="Price"
                                        />
                                        <ErrorMessage
                                            name="price"
                                            component="div"
                                        />
                                        <span className="span-text">{values.priceUnitSymbol}</span>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="input-container">
                                <label> Visibility</label>
                                <FastField
                                    className="input-area"
                                    component={CustomSelectField}
                                >
                                </FastField>
                                <ErrorMessage
                                    style={{
                                        fontSize: "10px",
                                        color: "#ea4335",
                                        marginTop: "3px",
                                        marginLeft: "10px",
                                    }}
                                    name="teamIds"
                                    component="div"
                                />
                            </div>
                            <div className="order-add-doc">
                                {
                                    <div
                                        className="upload__image-wrapper"
                                        onClick={(e) => {
                                            if (
                                                props.features &&
                                                !props.features.taskCreationAddDocs &&
                                                !superAdminRole
                                            ) {
                                                history.push({
                                                    pathname: "/Business/taskCreationAddDocs",
                                                });
                                            }
                                        }}
                                    >
                                        {props.formTaskData.libraryFiles &&
                                            props.formTaskData.libraryFiles.length < 7 && (
                                                <div
                                                    className="library-image"
                                                    onClick={changeProfileClick}
                                                >
                                                    <img src={uploadplusicon} alt="icon" />
                                                    <input
                                                        className="file-upload"
                                                        ref={uploadInput}
                                                        type="file"
                                                        name="image"
                                                        accept="image/*,.pdf"
                                                        onChange={(e) => {
                                                            uploadLibraryDocument(e);
                                                        }}
                                                        style={{ display: "none" }}
                                                    ></input>
                                                    <span>Add documents</span>
                                                </div>
                                            )}
                                    </div>
                                }
                            </div>
                            <div className="order-add-doc">
                                {props.formTaskData.libraryFiles &&
                                    props.formTaskData.libraryFiles.length
                                    ? props.formTaskData.libraryFiles.map((item, index) => {
                                        if (item.original) {
                                            let name = item.original.split("/");
                                            let ext = item.original
                                                .substring(
                                                    item.original.lastIndexOf(".") + 1,
                                                    item.original.length
                                                )
                                                .toLowerCase();
                                            return (
                                                <div className="upload__image-wrapper">
                                                    <div className="selected-docs">
                                                        <div className="close-btn">
                                                            <Button
                                                                className="selected-close-btn"
                                                                onClick={() => {
                                                                    deleteDocument(
                                                                        index,
                                                                        setFieldValue,
                                                                        props.formTaskData.libraryFiles
                                                                    );
                                                                }}
                                                            >
                                                                <img src={closeicon} alt="icon" />
                                                            </Button>
                                                        </div>
                                                        {ext === "pdf" ? (
                                                            <div
                                                                onClick={() => {
                                                                    if (item && item.original) {
                                                                        window.open(item.original, "_blank");
                                                                    }
                                                                }}
                                                            >
                                                                <span className="pdfvalue">PDF</span>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                onClick={() => {
                                                                    if (item && item.original) {
                                                                        window.open(item.original, "_blank");
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    class="original-image"
                                                                    src={item.original}
                                                                    alt="icon"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="filename">
                                                        <span>{name[4]}</span>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                    : null}
                                {((props.features && props.features.taskCreationAddDocs) ||
                                    superAdminRole) &&
                                    (!props.formTaskData.libraryFiles ||
                                        (props.formTaskData.libraryFiles &&
                                            props.formTaskData.libraryFiles.length === 0)) &&
                                    values.documents &&
                                    values.documents.length
                                    ? values.documents.map((item) => {
                                        return (
                                            <div className="upload__image-wrapper">
                                                <div
                                                    className="selected-docs"
                                                    onClick={() => {
                                                        if (item && item.original) {
                                                            window.open(item.original, "_blank");
                                                        }
                                                    }}
                                                >
                                                    <img
                                                        style={{ position: "relative" }}
                                                        src={documenticon}
                                                        alt="icon"
                                                    />
                                                </div>
                                                <div className="filename">
                                                    <span>{item.fileName}</span>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : null}
                            </div>
                        </>
                        )}
                        <div className="buttons">
                            <Button onClick={() => handleCancelTask("cancel")}>
                                Cancel
                            </Button>
                            <Button onClick={(e) => { e.preventDefault(); handleSubmit(values, { resetForm }) }}>
                                {props.buttonText}
                            </Button>
                        </div>
                    </div>
                </FormF>
            )}
            </Formik>
        </div>)
    }

    const checkToday = () => {
        let today = ""
        if (moment().format("YYYY-MM-DD") === moment(props.currentDate).format("YYYY-MM-DD")) {
            today = "Today."
        }
        return today
    }

    const handleCancelTask = (type) => {
        if (type === "back") {
            props.setButtonText("Create Task")
            props.setIsCreateTaskForm(false)
        }
        else if (type === "close") {
            props.setIsCreateTaskForm(false)
            props.setCreateTask(false)
        }
        else if (type === "close-optimize") {
            cancelOptimiseMission()
            props.setIsCreateTaskForm(false)
        }
        else if (type === "close-search") {
            props.setShowSearchComponent(false)
        }
        else {
            props.setIsCreateTaskForm(false)
            props.setCreateTask(false)
        }

        let initialValue = clearInitialValues()
        props.setAddress("")
        props.setLocation(null)
        props.setChangeStatus(false)
        props.setFormTaskData((prev) => {
            return {
                ...prev,
                ...initialValue
            }
        })
        setInputValue("")
    }

    const handleOptimiseSubmit = async (formValue, { resetForm }) => {
        try {
            props.setOptimiseFormData(formValue)
            if (formValue.multiMission) {
                return props.handleOptimiseSubmit(null, formValue)
            }
            else {
                let checkMMRequirements = await optimize.checkMMRequirement(formValue, [props.selectedMission])
                if (checkMMRequirements) {
                    props.setMultiMissionToggle(true)
                }
                else {
                    console.log("formValue", formValue)
                    return props.handleOptimiseSubmit(null, formValue)
                }
            }
        } catch (err) {
            console.log("error:::", err);
        }
    };

    const OptimiseSectionV2 = () => {
        return (<div className="bottom task-bar-bootom">
            <Formik
                initialValues={props.optimiseFormData}
                validationSchema={optimiseValidationSchema}
                onSubmit={handleOptimiseSubmit}
                enableReinitialize={false}
            >{({
                setFieldValue,
                setFieldTouched,
                values,
                resetForm,
                errors,
                touched,
            }) => (
                <FormF>
                    <div className="input-container">
                        <label>Start Location</label>
                        <Field
                            className="form-control"
                            name="startFrom"
                            as="select"
                        >
                            <option value="" selected>
                                Select Hubs
                            </option>
                            {hubList &&
                                hubList.length > 0 &&
                                hubList.map((t) => (
                                    <option value={t.id}>
                                        {t.hubName ? t.hubName : ""}
                                    </option>
                                ))}
                        </Field>
                        <ErrorMessage
                            style={{
                                fontSize: "10px",
                                color: "#ea4335",
                                marginTop: "3px",
                                marginLeft: "10px",
                            }}
                            name="startFrom"
                            component="div"
                        />
                    </div>
                    <div className="input-container">
                        <label>End Location</label>
                        <Field
                            className="form-control"
                            name="endAt"
                            as="select"
                        >
                            <option value="" selected>
                                Select Hubs
                            </option>
                            {hubList &&
                                hubList.length > 0 &&
                                hubList.map((t) => (
                                    <option value={t.id}>
                                        {t.hubName ? t.hubName : ""}
                                    </option>
                                ))}
                        </Field>
                        <ErrorMessage
                            style={{
                                fontSize: "10px",
                                color: "#ea4335",
                                marginTop: "3px",
                                marginLeft: "10px",
                            }}
                            name="endAt"
                            component="div"
                        />
                    </div>
                    <div className="optional-info" >
                        <div className="top" onClick={() => setMoreInfo(!moreInfo)}>
                            <div className="left">
                                <BsPencil className="icon" />
                                <p>More information (optional)</p>
                            </div>
                            <div className="right">
                                <MdKeyboardArrowDown className="icon  toggle-icon" />
                            </div>
                        </div>

                        {moreInfo && (<>
                            <div className="two-inputs">
                                <div className="input-container">
                                    <label>Mission duration</label>
                                    <Form.Group className="input-container-cost">
                                        <Field
                                            type="number"
                                            min="0"
                                            step="any"
                                            className="input-area"
                                            placeholder="Max mission duration"
                                            name="maxMissionDuration"
                                            aria-label="Max mission duration"
                                            value={values.maxMissionDuration}
                                        />
                                        <ErrorMessage
                                            name="maxMissionDuration"
                                            component="div"
                                        />
                                        <span>hour(s)</span>
                                    </Form.Group>

                                </div>
                                <div className="input-container">
                                    <label>Task per mission</label>
                                    <Form.Group className="input-container-cost">
                                        <Field
                                            type="number"
                                            min="0"
                                            max="98"
                                            className="input-area"
                                            placeholder="Max tasks per mission"
                                            name="maxMissionTasks"
                                            aria-label="Max tasks per mission"
                                            value={values.maxMissionTasks}
                                        />
                                        <ErrorMessage
                                            name="maxMissionTasks"
                                            component="div"
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="two-inputs">
                                <div className="input-container">
                                    <label>Team</label>
                                    <Form.Group>
                                        <Field
                                            className="form-control"
                                            name="teamId"
                                            as="select"
                                            value={values["teamId"]}
                                            onChange={(e) => {
                                                setFieldValue("teamId", e.target.value)
                                            }}
                                        >
                                            <option value="" selected>
                                                Select Team
                                            </option>
                                            {teamList &&
                                                teamList.length > 0 &&
                                                teamList.map((t) => (
                                                    <option value={t.id}>
                                                        {t.teamName ? t.teamName : ""}
                                                    </option>
                                                ))}
                                        </Field>
                                        <ErrorMessage
                                            style={{
                                                fontSize: "10px",
                                                color: "#ea4335",
                                                marginTop: "3px",
                                                marginLeft: "10px",
                                            }}
                                            name="teamId"
                                            component="div"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="input-container">
                                    <label>Agent</label>
                                    <Form.Group>
                                        <Field
                                            className="form-control"
                                            name="agentId"
                                            as="select"
                                        >
                                            <option value="" selected>
                                                Select Agent
                                            </option>
                                            {agentList &&
                                                agentList.length > 0 && values["teamId"] &&
                                                agentList.filter(a => {
                                                    return a.teamIds.some(t => t.id === values["teamId"])
                                                }).map((t) => (
                                                    <option value={t.id}>
                                                        {t.firstName ? t.firstName : ""} {t.lastName ? t.lastName : ""}
                                                    </option>
                                                ))}
                                        </Field>
                                        <ErrorMessage
                                            style={{
                                                fontSize: "10px",
                                                color: "#ea4335",
                                                marginTop: "3px",
                                                marginLeft: "10px",
                                            }}
                                            name="agentId"
                                            component="div"
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="two-inputs">
                                <div className="input-container">
                                    <label>Optimise Mode</label>
                                    <Form.Group>
                                        <Field
                                            className="form-control"
                                            name="optimiseMode"
                                            as="select"
                                            value={values["optimiseMode"]}
                                            onChange={(e) => {
                                                setFieldValue("optimiseMode", e.target.value)
                                            }}
                                        >
                                            <option value={"time"}>
                                                {"Time"}
                                            </option>
                                            <option value={"distance"}>
                                                {"Distance"}
                                            </option>
                                        </Field>
                                        <ErrorMessage
                                            style={{
                                                fontSize: "10px",
                                                color: "#ea4335",
                                                marginTop: "3px",
                                                marginLeft: "10px",
                                            }}
                                            name="optimiseMode"
                                            component="div"
                                        />
                                    </Form.Group>
                                </div>
                                {/* <div className="input-container">
                                    <label>Multi Mission</label>
                                    <Form.Group>
                                        <Field
                                            className="form-control"
                                            name="multiMission"
                                            as="select"
                                        >
                                            <option value={true}>
                                                Yes
                                            </option>
                                            <option value={false}>
                                                No
                                            </option>
                                        </Field>
                                        <ErrorMessage
                                            style={{
                                                fontSize: "10px",
                                                color: "#ea4335",
                                                marginTop: "3px",
                                                marginLeft: "10px",
                                            }}
                                            name="multiMission"
                                            component="div"
                                        />
                                    </Form.Group>
                                </div> */}
                            </div>
                        </>
                        )}
                        <div className="buttons">
                            <Button onClick={() => cancelOptimiseMission()}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                            // onClick={(e) => { e.preventDefault(); handleOptimiseSubmit(values, { resetForm }) }}
                            >
                                {"Go"}
                            </Button>
                        </div>
                    </div>
                </FormF>
            )}
            </Formik>
        </div>)
    };
    const taskDetailProps = {
        features: props.features,
        taskDetail: props.taskDetail,
        setTaskDetail: props.setTaskDetail,
        fromSearch,
        setShowSearchComponent: props.setShowSearchComponent
    }

    useEffect(() => {
        if (props.formTaskData.id) {
            setValue(props.formTaskData.orderNo || [])
        }
    }, [props.formTaskData])
    const ChangeStatusComponent = () => {
        return (
            <div className="bottom task-bar-bootom">
                <Formik
                    initialValues={props.formTaskData}
                    validationSchema={validationSchemaChangeStatus}
                    validate={validateCustomTaskStatus}
                    onSubmit={handleSubmitChangeStatus}
                    enableReinitialize={false}
                    render={({
                        errors,
                        values,
                        resetForm,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                    }) => {
                        return (
                            <FormF>
                                <div className="input-container">
                                    <label> Location</label>
                                    <Form.Group>
                                        {/* <MyAutoCompleteComponent {...autoCompleteProps} /> */}
                                        {/* <FaHome /> */}
                                        <Autocomplete
                                            // apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                            disabled
                                            // libraries={["places"]}
                                            className="input-area"
                                            value={props.address}
                                            onChange={(e) => { props.setAddress(e.target.value); props.setLocation(null) }}
                                            placeholder="Address"
                                            options={{ types: ["establishment", "geocode"], ...(countryCode && { componentRestrictions: { country: countryCode } }) }}
                                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                            onPlaceSelected={(place) => {
                                                props.setAddress(place.formatted_address);
                                                props.setLocation({
                                                    lat: place.geometry.location.lat(),
                                                    lng: place.geometry.location.lng(),
                                                });
                                            }}
                                        />
                                        {errors.address ? (
                                            <div className="error">{errors.address}</div>
                                        ) : null}
                                        {!props.address && (
                                            <ErrorMessage
                                                name="address"
                                                component="div"
                                            />
                                        )}
                                        {(!props.location || !props.location?.lat || !props.location?.lng) && (
                                            <ErrorMessage
                                                name="address"
                                                component="div"
                                            />
                                        )}
                                    </Form.Group>
                                </div>

                                <div className="two-inputs">
                                    <Form.Group className="input-container">
                                        <label>Recipient name</label>
                                        <Field
                                            disabled
                                            className="input-area"
                                            placeholder="John Doe"
                                            name="firstName"
                                            maxLength="15"
                                            onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                            aria-label="First Name"
                                        />
                                        <ErrorMessage
                                            name="firstName"
                                            component="div"
                                        />
                                    </Form.Group>
                                    <Form.Group className="input-container">
                                        <label>Phone Number</label>
                                        <div className="input-container-phone">
                                            <Field className="country-code" aria-label="Phone" name="countryCode" as="select"
                                                defaultValue={"+1"}
                                                disabled
                                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}>
                                                <option value="+1" >+1</option>
                                                <option value="+44" >+44</option>
                                                <option value="+91">+91</option>
                                            </Field>
                                            <Field
                                                disabled
                                                className="input-area input-area-phone"
                                                placeholder="Phone"
                                                name="phoneNumber"
                                                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault() } }}
                                                aria-label="Phone"
                                            />
                                        </div>
                                        <ErrorMessage
                                            name="phoneNumber"
                                            component="div"
                                        />
                                        <ErrorMessage name="countryCode" component="div" />
                                    </Form.Group>
                                </div>
                                <div className="input-container">
                                    <label>Change Status</label>
                                    <FastField
                                        className="form-control"
                                        name="taskStatus"
                                        as="select"
                                        // defaultValue={"started"}
                                        defaultValue=""
                                    >
                                        <option value="" selected>Select status</option>
                                        <option value="started">Started</option>
                                        <option value="reached">Reached</option>
                                        <option value="completed">Completed</option>
                                        <option value="failed">Failed</option>
                                    </FastField>
                                </div>
                                <div className="buttons">
                                    <Button onClick={() => handleCancelTask("cancel")}>
                                        Cancel
                                    </Button>
                                    <Button onClick={(e) => { e.preventDefault(); handleSubmitChangeStatus(values, { resetForm }) }}>
                                        {props.buttonText}
                                    </Button>
                                </div>
                            </FormF>
                        );
                    }}
                ></Formik>
            </div>)
    }

    const searchItemClick = (type, id) => {
        if (type === "customer") {
            history.push({
                pathname: "/Customer",
                state: { searchText: props.searchText, selectedId: id },
            });
        }
        if (type === "merchant") {
            history.push({
                pathname: "/Merchant",
                state: { searchText: props.searchText },
            });
        }
        if (type === "team") {
            history.push({
                pathname: "/Team",
                state: { searchText: props.searchText },
            });
        }
    };

    const searchTaskClickHandler = (e, task, i) => {
        props.setTaskDetail(task);
        let selectedCenter = {
            ...task.location, address: task.address, from: "task", id: task.id
        };
        props.setSelectedCenter(selectedCenter)
        props.setShowSearchComponent(false)
        setFromSearch(true)
    }

    const Tasks = (task, i) => {
        return (<div onClick={(e) => {
            searchTaskClickHandler(e, task, i)
        }} className={`${"mission-task"} ${task?.taskStatus ? `title-${task?.taskStatus}` : ""} ${task?.active ? "selected" : ""} ${props.unassignedList ? "unassigned-task" : ""}`}>
            <div className="pic">
                <p>{task?.orderType}</p>
            </div>
            <div className="text">
                <div className="mission-task-top">
                    <div className="name-and-address">
                        <h4>{renderCustomerName(task)}</h4>
                        <p>{task?.address}</p>
                    </div>
                    {task?.missioned && <p className="time">{task?.eta ? task?.eta : ""}</p>}
                </div>
                <div className="mission-task-bottom">
                    {task?.merchantId && task?.merchantId.role === 4 && task?.merchantId.companyName && <div className="btn">
                        <MdOutlineAllInbox className='icon' />
                        <p>{`${task?.merchantId.companyName}`}</p>
                    </div>}
                    {task?.merchantId && task?.merchantId.role === 2 && task?.merchantId.companyName && <div className="btn">
                        <MdOutlineAllInbox className='icon' />
                        <p>{`${task?.merchantId.companyName}`}</p>
                    </div>}
                    {task?.scheduledTime && <div className="btn">
                        <IoMdTime className='icon' />
                        <p>{formatAMPM(task?.scheduledTimeSeconds)}</p>
                    </div>}
                    {(task?.after || task?.before) && <div className="btn">
                        <IoMdTime className='icon' />
                        <p>{`${task?.after ? formatAMPM(task?.afterSeconds) : ""}-${task?.before ? formatAMPM(task?.beforeSeconds) : ""}`}</p>
                    </div>}
                </div>
            </div>

        </div>)
    }

    const SearchComponent = () => {
        return (
            <div className="searchbar-box-field">
                <div className="top">
                    <div className="close-btn">
                        <MdOutlineClose className="icon" onClick={() => { handleCancelTask("close-search") }} />
                    </div>
                </div>
                <div className="bottom">

                    {props.searchData.tasks && props.searchData.tasks.length > 0 && (
                        <div className="task-search">
                            <h2>Task</h2>
                            <div className="ts-box">
                                <div className="unassigned-field-list">

                                    {props.searchData.tasks.map((task, i) => {
                                        return (<div className="task-item">
                                            <h2>{task._id ? moment(task._id).format('dddd, LL') : "New Tasks"}</h2>
                                            {task.tasks?.map((t, ti) => { return (<>{Tasks(t, ti)}</>) })}
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                    {props.searchData.customers && props.searchData.customers.length > 0 && (
                        <div className="customer-search">
                            <h2>Customers</h2>
                            <div className="task-cs">
                                {props.searchData.customers.map((u, i) => {
                                    return (
                                        <div
                                            key={u.id}
                                            className="task-cs-box"
                                            onClick={() => {
                                                searchItemClick("customer", u._id);
                                            }}
                                        >
                                            <div className="task-cs-icon">
                                                <img src={profilecirclicon} alt="icon" />
                                            </div>
                                            <div className="task-cs-data">
                                                <h2>
                                                    {u.firstName ? u.firstName : ""}{" "}
                                                    {u.lastName ? u.lastName : ""}
                                                </h2>
                                                <h3>{u.address ? u.address : ""}</h3>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {props.searchData.agent && props.searchData.agent.length > 0 && (
                        <div className="customer-search">
                            <h2>Agent</h2>
                            <div className="task-cs">
                                {props.searchData.agent.map((u, i) => {
                                    return (
                                        <div
                                            key={u.id}
                                            className="task-cs-box"
                                            onClick={() => {
                                                searchItemClick("team");
                                            }}
                                        >
                                            <div className="task-cs-icon">
                                                <img src={profilecirclicon} alt="icon" />
                                            </div>
                                            <div className="task-cs-data">
                                                <h2>
                                                    {u.firstName ? u.firstName : ""}{" "}
                                                    {u.lastName ? u.lastName : ""}
                                                </h2>
                                                <h3>{u.address ? u.address : ""}</h3>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {props.searchData.merchant && props.searchData.merchant.length > 0 && (
                        <div className="customer-search">
                            <h2>Merchant</h2>
                            <div className="task-cs">
                                {props.searchData.merchant.map((u, i) => {
                                    return (
                                        <div
                                            key={u.id}
                                            className="task-cs-box"
                                            onClick={() => {
                                                searchItemClick("merchant");
                                            }}
                                        >
                                            <div className="task-cs-icon">
                                                <img src={profilecirclicon} alt="icon" />
                                            </div>
                                            <div className="task-cs-data">
                                                <h2>
                                                    {u.firstName ? u.firstName : ""}{" "}
                                                    {u.lastName ? u.lastName : ""}
                                                </h2>
                                                <h3>{u.address ? u.address : ""}</h3>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const Component = () => {
        if (props.showOptimiseSection) {
            return (<div className="task-bar-right">
                <div className="wrapper">
                    <div className="top-wrapper">
                        <div className="back-btn">
                            <h3 className="optiimze-title">Optimize mission</h3>
                        </div>
                        <div className="task-bar-close-btn">
                            <MdOutlineClose className="icon" onClick={() => { handleCancelTask("close-optimize") }} />
                        </div>
                    </div>
                    {OptimiseSectionV2()}
                </div>
            </div>)
        }
        else if (props.showSearchComponent) {
            return (<>{SearchComponent()}</>)
        }
        else {
            return (<>
                {!props.createTask && <div className="right-side-bar-wrapper">
                    {props.taskDetail ? <TaskRightSideDetails {...taskDetailProps} /> : <>
                        {props.viewType === appDefaults.VIEWS.SUPERVISE_CONTROL && <div className="top">
                            <div className="arrow-btns">
                                <button className="arrow-button" onClick={() => props.handleArrowClickS('back')}>
                                    <IoIosArrowBack className="arrow-icon" />
                                </button>
                                <button className="arrow-button" onClick={() => props.handleArrowClickS('forward')}>
                                    <IoIosArrowForward className="arrow-icon" />
                                </button>
                            </div>
                            <div className="date-today" onClick={() => setShowCalendar(!showCalendar)}>
                                <p> <span className="tdy-s">{checkToday()}</span> {moment(props.currentDate).format("LL")}</p>
                            </div>
                        </div>}
                        {props.changeTaskStatus && ChangeStatusComponent()}
                        {showCalendar && <Calendar
                            onChange={(e) => {
                                // setCurrentDate(e);
                                //date chhange in dashboard also
                                props.setCurrentDate(e);
                                localStorage.setItem("currentDate", e)
                                setShowCalendar(false);
                            }}
                            value={props.currentDate}
                        />}

                        {props.showScheduleDelivery && <ScheduleDelivery {...scheduleDeliveryProps} />}
                        <div className="bottom">
                            <Sections {...props} rightSideBar={true} sectionType={props.viewType === appDefaults.VIEWS.PLANNER_CONTROL ? appDefaults.SECTIONS.PLANNER_CONTROL : appDefaults.SECTIONS.SUPERVISE_CONTROL} />
                        </div>
                    </>}
                </div>}
                {props.createTask && <div className="task-bar-right">
                    <div className="wrapper">
                        <div className="top-wrapper">
                            <div className="back-btn" onClick={() => { handleCancelTask("back") }}>
                                <HiOutlineArrowSmLeft className="icon" />
                                <h3>{props.buttonText}</h3>
                            </div>
                            <div className="task-bar-close-btn">
                                <MdOutlineClose className="icon" onClick={() => { handleCancelTask("close") }} />
                            </div>
                        </div>
                        {!props.isCreateTaskForm && <div className="top task-bar-top">
                            <div className="add-new-tsk" onClick={() => { props.setButtonText("Create Task"); props.setIsCreateTaskForm(true) }}>
                                <HiPlus className="icon" />
                                <p>Add New Task</p>
                            </div>
                            <div className="import-data" onClick={(e) => { props.importPopup() }}>
                                <TfiImport className="icon" />
                                <p>Import Data</p>
                            </div>
                        </div>}
                        {props.isCreateTaskForm && createTaskComponent()}
                    </div>
                </div>}</>)
        }
    }

    return (<div className="right-sidebar-container">
        {Component()}
        <div className="side-bar-right-arrow-btns">
            {props?.rightSidebarOpen && (
                <img src={arrow_in_circle} className="arrow arrow-right" alt={"arrow"} onClick={props.closeSidebar} />
                // <TfiArrowCircleRight
                //     onClick={props.closeSidebar}
                //     className="arrow arrow-right"
                // />
            )}
            {!props?.rightSidebarOpen && (
                <img src={arrow_in_circle} className="arrow arrow-left" alt={"arrow"} onClick={props.openSidebar} />

            )}
        </div>
    </div>)
}

export default RightSideBar